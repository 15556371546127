<template>
  <v-container fluid>
    <v-data-table
      :headers="itemHeader"
      :items="items"
      :loading="loading"
      :items-per-page="-1"
      class="elevation-1 "
    >
      <template v-slot:top>
        <v-toolbar flat class="mb-7">
          <!-- add / edit item component -->
          <ItemForm
            @closeDialog="openDialog = false"
            @formSubmitted="formSubmitted"
            :formTitle="formTitle"
            :dialog="openDialog"
            :record="record"
            :collection="collection"
            v-if="openDialog"
            :items="items"
          />
        </v-toolbar>
      </template>

      <template
        v-slot:item.actions="{ item }"
        style="textAlign:right !important"
      >
        <v-icon color="primary" small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import ItemForm from "./comp/enrollmentsForm";

export default {
  components: {
    ItemForm,
  },
  props: ["items", "itemHeader", "collection"],
  data() {
    return {
      loading: false,
      openDialog: false,
      formTitle: "Add New Item",
      record: null,
    };
  },

  methods: {
    async loadData() {
      try {
        this.$emit("loadData");
      } catch (error) {
        console.log(error);
      }
    },

    editItem(item) {
      this.record = item;
      this.formTitle = "Edit Record";
      this.openDialog = true;
    },

    formSubmitted(msg) {
      this.openDialog = false;
      alert(msg);
      this.loadData();
    },
  },
};
</script>

<style></style>
