<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" lg="12">
        <base-material-chart-card
          :data="userInCoursesChart.data"
          :options="userInCoursesChart.options"
          :responsive-options="userInCoursesChart.responsiveOptions"
          color="#E91E63"
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" color="info" icon v-on="on">
                  <v-icon color="info">
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" light icon v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            عدد المشتركين في كل دورة
          </h4>
        </base-material-chart-card>
      </v-col>

      <v-col cols="12" lg="12">
        <base-material-chart-card
          :data="userInCouponsChart.data"
          :options="userInCouponsChart.options"
          :responsive-options="userInCouponsChart.responsiveOptions"
          color="success"
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" color="info" icon v-on="on">
                  <v-icon color="info">
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" light icon v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            عدد المشتركين في كل مقياس
          </h4>
        </base-material-chart-card>
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="info"
          icon="mdi-account"
          title="عدد المسجلين"
          :value="usersNum + ''"
          sub-icon="mdi-clock"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          title="عدد العمليات الناجحة"
          :value="enrollmentSuccNum + ''"
          sub-icon="mdi-tag"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          title="عدد الكوبونات المجانية"
          :value="isSuper + ''"
          sub-icon="mdi-tag"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="success"
          icon="mdi-store"
          title="إجمالي الأرباح"
          :value="profits + ''"
          sub-icon="mdi-calendar"
        />
      </v-col>

      <v-col cols="12" md="12">
        <base-material-card color="warning" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              الإشتراكات على الموقع
            </div>
          </template>
          <v-card-text>
            <v-form ref="form">
              <v-row class="fill-height" align="center" justify="center">
                <v-col md="4">
                  <v-autocomplete
                    :loading="loadingCourses"
                    v-model="course"
                    :items="courses"
                    dense
                    chips
                    small-chips
                    label="الدورات"
                    item-text="title"
                    item-value="id"
                    @change="getEnrollmentsData"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
            <SettingItem
              @loadData="getEnrollmentsData"
              collection="enrollment"
              :items="items"
              :itemHeader="headers"
            />
          </v-card-text>
        </base-material-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-btn @click="downloadCSVData">Download</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from "@/services/api.service";
import SettingItem from "./SettingItem/index";

export default {
  name: "DashboardDashboard",
  components: {
    SettingItem,
  },
  data() {
    return {
      enrollmentFailNum: null,
      enrollmentSuccNum: null,
      courses: [],
      loadingCourses: false,
      course: null,
      usersNum: null,
      profits: 0,
      isSuper: 0,
      userInCouponsChart: {
        data: {
          labels: [],
          series: [],
        },
        options: {
          // lineSmooth: this.$chartist.Interpolation.cardinal({
          //   tension: 0,
          // }),
          axisX: {
            showGrid: true,
          },
          low: 0,
          high: 0,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      userInCoursesChart: {
        data: {
          labels: [],
          series: [],
        },
        options: {
          axisX: {
            showGrid: true,
          },
          low: 0,
          high: 0,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      headers: [
        {
          sortable: false,
          text: "ID",
          value: "id",
        },
        {
          sortable: false,
          text: "email",
          value: "student_id.email",
        },
        {
          sortable: false,
          text: "product",
          value: "course_id.title",
          align: "right",
        },
        {
          sortable: false,
          text: "coupon code",
          value: "coupon_id.code",
          align: "right",
        },
        {
          sortable: false,
          text: "price",
          value: "price",
          align: "right",
        },
        {
          sortable: false,
          text: "payment status",
          value: "payment_status",
          align: "right",
        },
        {
          sortable: false,
          text: "result",
          value: "result_option_id.result",
          align: "right",
        },
        { text: "", value: "actions", sortable: false },
      ],
      items: [],
      tabs: 0,
      tasks: {
        0: [
          {
            text:
              'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
          {
            text:
              "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false,
          },
          {
            text:
              "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: false,
          },
          {
            text: "Create 4 Invisible User Experiences you Never Knew About",
            value: true,
          },
        ],
        1: [
          {
            text:
              "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true,
          },
          {
            text:
              'Sign contract for "What are conference organizers afraid of?"',
            value: false,
          },
        ],
        2: [
          {
            text:
              "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false,
          },
          {
            text:
              "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true,
          },
          {
            text:
              'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
        ],
      },
      list: {
        0: false,
        1: false,
        2: false,
      },
    };
  },
  mounted() {
    this.getCoursesData();
    this.getCourses();
    this.getCouponsData();
    this.getIndicators();
    this.getEnrollmentsData();
    this.getUsersData();
  },
  methods: {
    async getCourses() {
      this.loadingCourses = true;
      const { data } = await ApiService.get("items/courses?fields=id,title");
      this.courses = data.data;
      this.loadingCourses = false;
    },
    async getCoursesData() {
      const data = await ApiService.get(
        "items/courses?fields=id,title,type_id,enrollment.id,enrollment.payment_status&limit=10&filter[enrollment.payment_status]=success&filter[type_id]=1"
      );
      let series;
      series = [];
      data.data.data.forEach((element) => {
        this.userInCoursesChart.data.labels.push(element.title);
        series.push(element.enrollment.length);
        if (this.userInCoursesChart.options.high < element.enrollment.length)
          this.userInCoursesChart.options.high = element.enrollment.length;
      });
      this.userInCoursesChart.data.series.push(series);
    },
    async getIndicators() {
      const data = await ApiService.get(
        "items/courses?fields=id,title,type_id,enrollment.id,enrollment.payment_status&limit=10&filter[enrollment.payment_status]=success&filter[type_id]=2"
      );
      let series;
      series = [];
      data.data.data.forEach((element) => {
        this.userInCouponsChart.data.labels.push(element.title);
        series.push(element.enrollment.length);
        if (this.userInCouponsChart.options.high < element.enrollment.length)
          this.userInCouponsChart.options.high = element.enrollment.length;
      });
      this.userInCouponsChart.data.series.push(series);
    },
    async getCouponsData() {
      const data = await ApiService.get(
        "items/coupons?fields=id,code&filter[is_super]=true"
      );
      this.isSuper = data.data.data.length + "";
    },
    async getEnrollmentsData() {
      let url =
        "items/enrollment?fields=id,payment_status,price,student_id.email,course_id.title,course_id.id,coupon_id.code,result_option_id.result,result_option_id.id&sort=-date,-id";
      if (this.course) url += `&filter[course_id.id]=${this.course}&limit=-1`;
      // else url += `&limit=10`;
      const { data } = await ApiService.get(url);
      this.items = data.data;
      let success = data.data.filter((enrollment) => {
        return enrollment.payment_status == "success";
      });

      let faild = data.data.filter((enrollment) => {
        return enrollment.payment_status == "faild";
      });

      this.enrollmentSuccNum = success.length + "";
      this.enrollmentFailNum = faild.length + "";
      let num = 0;
      success.forEach((element) => {
        num += element.price ? parseInt(element.price) : 0;
      });
      this.profits = num + " KWD";
    },
    downloadCSVData() {
      let csv = "id,email,product,coupon code,price,payment status,result\n";
      this.items.forEach((row) => {
        csv += `${row.id},${row.student_id.email},${row.course_id.title},${
          row.coupon_id ? row.coupon_id.code : null
        },${row.price},${row.payment_status},${
          row.result_option_id ? row.result_option_id.result : null
        }`;
        csv += "\n";
      });

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = "enrollments.csv";
      anchor.click();
    },
    async getUsersData() {
      const data = await ApiService.get(
        "users?fields=id&filter[role]=3&limit=-1"
      );
      this.usersNum = data.data.data.length + "";
    },
    complete(index) {
      this.list[index] = !this.list[index];
    },
  },
};
</script>
