<template>
  <v-dialog v-model="dialog" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" style="padding:50px">
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                :loading="loadingresultOptions"
                v-model="formData.result_option_id"
                :items="resultOptions"
                dense
                chips
                small-chips
                label="النتائج"
                item-text="result"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          إلغاء
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          إرسال
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["formTitle", "record", "dialog", "items", "collection"],
  data() {
    return {
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      resultOptions: [],
      loadingresultOptions: false,
      formData: {
        id: null,
        result_option_id: null,
      },
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          await ApiService.update(
            `items/${this.collection}`,
            this.formData.id,
            { result_option_id: this.formData.result_option_id }
          );
          this.submitted = false;
          const msg = "record updated successfully";
          this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
  },

  async mounted() {
    const resultOptions = await ApiService.get(
      `items/result_options?fields=id,result&filter[course_id]=${this.record.course_id.id}`
    );
    this.resultOptions = resultOptions.data.data;
    if (this.record) {
      Object.keys(this.formData).forEach((element) => {
        if (this.record[element]) {
          if (typeof this.record[element] == "object") {
            this.formData[element] = this.record[element].id;
          } else {
            this.formData[element] = this.record[element];
          }
        }
      });
    }
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
